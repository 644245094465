import {useCallback, useEffect} from 'react';
import {styled} from 'styled-components';

import {groupBy} from '@shared/lib/array_utils';
import {asMapArrayOrThrow, asStringOrThrow} from '@shared/lib/type_utils';

import {Button} from '@shared-web/components/core/button';
import {useStateRef} from '@shared-web/lib/use_state_ref';

const PARALLEL_REMOVE = 1;

interface Device {
  friendly_name: string;
  definition?: {
    description?: string;
    vendor?: string;
    model?: string;
  };
}

export const HomePage: React.FC = () => {
  const [devices, setDevices, devicesRef] = useStateRef<{
    ws: WebSocket;
    supported: Device[];
    notSupported: Device[];
    devicesToRemove: string[];
    shouldRemove?: boolean;
  }>();

  const handleClick = useCallback(async () => {
    return await new Promise<void>(resolve => {
      const ws = new WebSocket('ws://192.168.1.167:8080/api');
      ws.addEventListener('message', event => {
        const msgStr = asStringOrThrow(event.data);
        const msg = JSON.parse(msgStr) as {payload: unknown; topic: string};
        if (msg.topic === 'bridge/devices') {
          const devices = asMapArrayOrThrow(msg.payload);
          const groups = groupBy(
            devices,
            d =>
              ('supported' in d && d['supported'] === true) ||
              ('interview_completed' in d && d['interview_completed'] === true)
          );
          const supported = (groups.get(true) ?? []) as Device[];
          const notSupported = (groups.get(false) ?? []) as Device[];
          const {shouldRemove, devicesToRemove = []} = devicesRef.current ?? {};
          setDevices({
            ws,
            supported,
            notSupported,
            shouldRemove,
            devicesToRemove: devicesToRemove.filter(
              toRemove => notSupported.find(d => d.friendly_name === toRemove) !== undefined
            ),
          });
          resolve();
        }
      });
    });
  }, [devicesRef, setDevices]);

  const handleLogClick = useCallback(() => {
    console.log(devices);
  }, [devices]);

  const handleRemoveStartClick = useCallback(() => {
    if (!devicesRef.current) {
      return;
    }
    setDevices({...devicesRef.current, shouldRemove: true});
  }, [devicesRef, setDevices]);

  const handleRemoveStopClick = useCallback(() => {
    if (!devicesRef.current) {
      return;
    }
    setDevices({...devicesRef.current, shouldRemove: false});
  }, [devicesRef, setDevices]);

  useEffect(() => {
    if (!devices?.shouldRemove || devices.devicesToRemove.length >= PARALLEL_REMOVE) {
      return;
    }
    const nextToRemove = devices.notSupported.find(
      d => !devices.devicesToRemove.includes(d.friendly_name)
    );
    if (!nextToRemove) {
      if (devices.devicesToRemove.length === 0) {
        setDevices({...devices, shouldRemove: false});
      }
      return;
    }
    setDevices({
      ...devices,
      devicesToRemove: [...devices.devicesToRemove, nextToRemove.friendly_name],
    });
    devices.ws.send(
      JSON.stringify({
        topic: 'bridge/request/device/remove',
        payload: {
          id: nextToRemove.friendly_name,
          force: true,
          block: true,
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          transaction: Math.random().toString(36).slice(2),
        },
      })
    );
  }, [devices, setDevices]);

  return (
    <Wrapper>
      <Line>
        <Button onClickAsync={handleClick}>FETCH</Button>
        <Button onClick={handleLogClick}>LOG</Button>
      </Line>
      {devices === undefined ? (
        <></>
      ) : (
        <>
          <Line>
            <Title>{`${devices.notSupported.length.toLocaleString()} Un-supported devices`}</Title>
            <Button
              onClick={devices.shouldRemove ? handleRemoveStopClick : handleRemoveStartClick}
            >{`${devices.shouldRemove ? 'STOP' : 'START'} REMOVE`}</Button>
          </Line>
          <Title>{`Supported devices (${devices.supported.length})`}</Title>
          <Table>
            <thead>
              <Row>
                <HeaderCell>#</HeaderCell>
                <HeaderCell>Friendly name</HeaderCell>
                <HeaderCell>Description</HeaderCell>
                <HeaderCell>Vendor</HeaderCell>
                <HeaderCell>Model</HeaderCell>
              </Row>
            </thead>
            <tbody>
              {devices.supported.map((d, i) => (
                <Row $even={i % 2 === 1} key={d.friendly_name}>
                  <Cell>{(i + 1).toLocaleString()}</Cell>
                  <Cell>{d.friendly_name}</Cell>
                  <Cell>{d.definition?.description ?? '-'}</Cell>
                  <Cell>{d.definition?.vendor ?? '-'}</Cell>
                  <Cell>{d.definition?.model ?? '-'}</Cell>
                </Row>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </Wrapper>
  );
};
HomePage.displayName = 'HomePage';

const Wrapper = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
`;

const Title = styled.div`
  font-size: 24px;
`;

const Table = styled.table`
  border: solid 2px #0049be;
  border-top: none;
  width: max-content;
`;
const Row = styled.tr<{$even?: boolean}>`
  background-color: ${p => (p.$even ? '#00000011' : 'transparent')};
  &:hover {
    cursor: pointer;
    background-color: #00000022;
  }
`;
const HeaderCell = styled.th`
  padding: 8px 12px;
  background: #0049be;
  color: #ffffff;
  vertical-align: middle;
`;
// const HeaderNumberCell = styled(HeaderCell)`
//   text-align: right;
// `;
const Cell = styled.td<{$alignRight?: boolean}>`
  padding: 8px 12px;
  white-space: nowrap;
  vertical-align: middle;
  ${p => p.$alignRight && 'text-align: right;'}
`;
// const NumberCell = styled(Cell)`
//   text-align: right;
// `;

const Line = styled.div`
  display: flex;
  align-items: baseline;
  gap: 16px;
`;
